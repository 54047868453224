import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import BackgroundImage from 'gatsby-background-image'
import PageBuilder from '../components/pageBuilder'

export const query = graphql`
	query {
		sanityFrontPage {
			frontBannerImages {
				asset {
					id
					fluid {
						...GatsbySanityImageFluid
					}
				}
			}
			...FrontPageBuilder
		}
	}
	`

const IndexPage = ({data}) => {
	const images = data.sanityFrontPage.frontBannerImages
	const pageBuilder = data.sanityFrontPage.pageBuilder

	return (
		<Layout>
			<SEO title="Online conductors" />
				<div className="container mx-auto">
					<Carousel 
						showArrows={false}
						showIndicators={false}
						showStatus={false}
						autoPlay={true}
						infiniteLoop={true}
						showThumbs={false}
						stopOnHover={false}
						interval={6000}
						transitionTime={700}
					>
					{images.map(image =>
					<div className="h-80 md:h-banner" key={image.asset.id}>
								<BackgroundImage
									className={'h-full relative'}
									fluid={image.asset.fluid}
									backgroundColor={`#040e18`}
								>
								</BackgroundImage>
						</div>
					)}
					</Carousel>
				</div>
			<div className="container mx-auto px-3 lg:px-0 mt-10">
					<PageBuilder pageBuilder={pageBuilder}/>
			</div>
		</Layout>
	)
}

export default IndexPage
